import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { API } from "../../constants/API";
import { RootState } from "../store";

export interface budgetUserdata {
  budgetId: string;
  approve_status: number;
  comment: string;
  user_id: string;
}
interface Userstate {
  budgetuserData: budgetUserdata;
  user: budgetUserdata[];
  isLoading?: boolean;
  isError?: boolean;
  status?: string;
  statusCode?: number;
  message?: string;
  budgetUserStatus?: number;
}

const initialState: Userstate = {
  user: [],
  budgetUserStatus: 0,
  isLoading: false,
  isError: false,
  status: "",
  statusCode: 0,
  message: "",
  budgetuserData: {
    budgetId: "",
    approve_status: 0,
    comment: "",
    user_id: "",
  },
};

export const fetchApprovalDeclinedData = createAsyncThunk(
  "fetchApprovalDeclinedData",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(API.FETCHAPPROVALDECLINEDDATA, data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

const ApprovalDeclineSlice = createSlice({
  name: "approvalDecline",
  initialState,
  reducers: {
    changeStatus(state, action) {
      state.budgetuserData.approve_status = action.payload;
    },
    emptyBudgetModal: (state) => {
      state.budgetuserData = initialState.budgetuserData;
      state.isError = initialState.isError;
      state.isLoading = initialState.isLoading;
      state.message = initialState.message;
      state.status = initialState.status;
      state.statusCode = initialState.statusCode;
      state.user = initialState.user;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchApprovalDeclinedData.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(
        fetchApprovalDeclinedData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = "succeeded";
          state.isLoading = false;
          state.budgetuserData = action.payload.data;
          state.statusCode = action.payload.status;
          state.message = action.payload.data.message;
          state.budgetUserStatus = action.payload.data.budget.status;
        }
      )
      .addCase(
        fetchApprovalDeclinedData.rejected,
        (state, action: PayloadAction<any>) => {
          state.status = "failed";
          state.isLoading = false;
          state.statusCode = action.payload.status;
          // state.message = action.payload.data.message;
          state.message = action.payload.data.message
            ? action.payload.data.message
            : action.payload.data.error;
        }
      );
  },
});

export const { changeStatus, emptyBudgetModal } = ApprovalDeclineSlice.actions;
export const budgetuserData = (state: RootState) =>
  state.ApprovedDeclined.budgetuserData;
export const budgetMessage = (state: RootState) =>
  state.ApprovedDeclined.message;
export const budgetStatus = (state: RootState) => state.ApprovedDeclined.status;

export default ApprovalDeclineSlice.reducer;
