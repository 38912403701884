import { AuthenticationResult, EventType, PublicClientApplication } from "@azure/msal-browser";
import { getCurrentToken } from "./tokenFetcher";
import { msalConfig, loginRequest } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

export function initializeMsal() {
  console.log("=> msal initialization..");

  // Check for any existing accounts
  // const accounts = msalInstance.getAllAccounts();

  // if (accounts.length > 0) {
  //   // Optionally, set the first account only if no active account exists
  //   const activeAccount = msalInstance.getActiveAccount();
  //   if (!activeAccount) {
  //     msalInstance.setActiveAccount(accounts[0]);
  //     console.log("Active account set to:", accounts[0].username);
  //   }
  // }

  // Enable storage events for account changes across tabs
  msalInstance.enableAccountStorageEvents();

  // Event callback for login success or account change
  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;

      // Dynamically set the active account to the currently logged-in account
      msalInstance.setActiveAccount(account);
      console.log("Active account updated to:", account.username);
    }
  });
}

export async function getToken() {
  const activeAccount = msalInstance.getActiveAccount();
  if (!activeAccount) {
    console.error("No active account! Login first.");
    return null;
  }

  const authToken = await getCurrentToken(msalInstance);
  console.log("AUTH TOKEN:", authToken);

  return authToken;
}

// Login through a sign-in button
export const handleLogin = (loginType = "redirect") => {
  if (loginType === "popup") {
    msalInstance.loginPopup(loginRequest).catch((e: any) => {
      console.error(`loginPopup failed: ${e}`);
    });
  } else if (loginType === "redirect") {
    msalInstance.loginRedirect(loginRequest).catch((e: any) => {
      console.error(`loginRedirect failed: ${e}`);
    });
  }
};

// Logout through a logout button
export const handleLogout = (logoutType = "redirect") => {
  const activeAccount = msalInstance.getActiveAccount();
  if (!activeAccount) {
    console.warn("No active account found during logout.");
    return;
  }

  if (logoutType === "popup") {
    msalInstance.logoutPopup({ account: activeAccount }).catch((e: any) => {
      console.error(`logoutPopup failed: ${e}`);
    });
  } else if (logoutType === "redirect") {
    msalInstance.logoutRedirect({
      account: activeAccount,
      postLogoutRedirectUri: process.env.NEXT_PUBLIC_REDIRECT_URI,
    }).catch((e: any) => {
      console.error(`logoutRedirect failed: ${e}`);
    });
  }
};
