import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import { API } from "../../constants/API";

interface roleObject {
  _id: string;
  roleName: string;
}

interface roleState {
  isLoading?: boolean;
  role?: roleObject[];
  isError?: boolean;
}

const initialState: roleState = {
  isLoading: false,
  role: [],
  isError: false,
};

export const fetchRole = createAsyncThunk(
  "fetchrole",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(API.FETCHROLES);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const roleSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRole.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchRole.fulfilled, (state, action) => {
      state.isLoading = false;
      state.role = action.payload.data;
    });
    builder.addCase(fetchRole.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export const roles = (state: RootState) => state.roleReducer.role;

export default roleSlice.reducer;
