import { useEffect } from "react";
import { initializeMsal, msalInstance } from "./msal";
import { AuthenticatedTemplate, MsalAuthenticationTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react"
import { InteractionType } from "@azure/msal-browser";
import { userDataLoginRequest } from "./authConfig";
// import UnauthorizedMessage from "@/components/UnauthorizedMessage";

const MyMsalProvider = ({ children }: { children: React.ReactNode }) => {
    useEffect(() => {
    initializeMsal();
    }, []);

    return (
        <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate
              interactionType={InteractionType.Redirect}
              authenticationRequest={userDataLoginRequest}
            //   errorComponent={ErrorComponent}
            //   loadingComponent={Loader}
            >
              {children}
            {/* Below code useful for login though sing-in button */}
            {/* <AuthenticatedTemplate>
                {children}
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                <UnauthorizedMessage />
                </UnauthenticatedTemplate> */}
            </MsalAuthenticationTemplate>
        </MsalProvider>
    );
};

export default MyMsalProvider;