export const cleanedNumber = (numberString: any) =>
  parseFloat(numberString.replace(/,/g, "")).toFixed(2);

export const formatCurrency = (value: number, toFixed?: number) => {
  return (
    (value < 0 ? "-" : "") + // Check if value is negative and add "-" if true
    "$" +
    Math.abs(Number(value)) // Use Math.abs to get the absolute value for formatting
      .toFixed(toFixed ?? 2) // Keep the decimal places as defined by `toFixed`
      .replace(/\d(?=(\d{3})+\.)/g, "$&,") // Add commas for thousands
  );
};



export const downloadExcelData=(response:any)=>{
  const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const link = document.createElement('a');
  link.download = 'isbn.xlsx';
  link.href = URL.createObjectURL(blob);
  link.click();
  URL.revokeObjectURL(link.href);
}