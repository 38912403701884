import { RouterProvider, useNavigate } from "react-router-dom";
import { routes } from "./router/route";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import the CSS for Toastify
import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./redux/store";
import { fetchUserInfo, updateLoginUserData, user } from "./redux/reducers/UserReducer";
import MyMsalProvider from "./msal/MyMsalProvider";
import { loginUser } from "./redux/reducers/profileReducer";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

function App() {
  const dispatch = useDispatch<AppDispatch>();
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
  const userData = useSelector(user);
  const token = localStorage.getItem("token");

  const loginUserStatus = useSelector((state:RootState) => state.profileReducer.statusCode)

  const loginUserData = useSelector((state:RootState) => state.profileReducer.userData)

  const isAuthenticated = useIsAuthenticated()

  const {instance} = useMsal()

  const account = instance.getActiveAccount()
  
  useEffect(() => {
    if (isAuthenticated && !token) {
      dispatch(loginUser({email:account?.username}))
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if(loginUserStatus === 200) dispatch(updateLoginUserData(loginUserData))
  },[loginUserStatus])

  useEffect(() => {
    // if(token)
    if(token && userData?.status === 401){
      localStorage.removeItem("token")
      localStorage.removeItem("userroles")
      toast.dismiss()
      // toast.error(userData.message);
      return;
    }
    if (token && userData?.status != 401 && userData?.status != 200) {
      axios.defaults.headers["authentication"] = token;
      dispatch(fetchUserInfo());
    }
  }, [userData]);

  return (
    <>
    {/* <MyMsalProvider> */}
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <RouterProvider router={routes} />
    {/* </MyMsalProvider> */}
    </>
  );
}

export default App;
